import {
  ElementType,
  forwardRef,
  ForwardRefRenderFunction,
  RefCallback,
} from 'react';
import { IMaskInput } from 'react-imask';

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const PercentMask: ForwardRefRenderFunction<HTMLElement, CustomProps> = (
  props,
  ref,
) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="num %"
      blocks={{
        num: {
          mask: Number,
          thousandsSeparator: '.',
          padFractionalZeros: false,
          radix: ',',
          mapToRadix: [','],
          scale: 0,
          normalizeZeros: false,
          min: 0,
          max: 100,
        },
      }}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value: value as string } })
      }
    />
  );
};

export default forwardRef(PercentMask) as ElementType;

import { Controller, useFormContext } from 'react-hook-form';
import { FormErrorMessage, Switch, SwitchProps } from '@chakra-ui/react';
import FormControl from './FormControl';
import { LabelWithDescription } from 'src/pages/dashboard/courses/components/LabelWithDescription';

type SwitchFieldProps = SwitchProps & {
  name: string;
  label?: string;
  description?: string;
};

const SwitchField = (props: SwitchFieldProps) => {
  const { control } = useFormContext();
  const { name, onChange, label, description, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: onChangeHookForm, onBlur },
        formState: { isSubmitted },
        fieldState: { isTouched, error },
      }) => (
        <FormControl
          isInvalid={error && (isTouched || isSubmitted)}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="4"
        >
          {label && (
            <LabelWithDescription
              title={label}
              description={description || ''}
              htmlFor={name}
            />
          )}

          <Switch
            {...fieldProps}
            name={name}
            isChecked={value}
            onChange={(e) => {
              onChangeHookForm(e);

              if (onChange) onChange(e);
            }}
            onBlur={onBlur}
            size={description ? 'lg' : 'md'}
          />

          {error && (isTouched || isSubmitted) && (
            <FormErrorMessage>{error.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

export default SwitchField;

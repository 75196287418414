import * as dateFns from 'date-fns';
import {
  ElementType,
  forwardRef,
  ForwardRefRenderFunction,
  RefCallback,
} from 'react';
import { IMaskInput } from 'react-imask';

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  maxDate?: Date;
  minDate?: Date;
};

const timezoneOffsetInHours = new Date().getTimezoneOffset() / 60;

const DateMask: ForwardRefRenderFunction<HTMLElement, CustomProps> = (
  props,
  ref,
) => {
  const { onChange, ...other } = props;

  const format = (date: Date) => {
    const dateWithTimezone = dateFns.add(date, {
      hours: timezoneOffsetInHours,
    });
    return dateFns.format(dateWithTimezone, 'dd/MM/yyyy');
  };

  const parse = (str: string) => {
    const date = dateFns.parse(str, 'dd/MM/yyyy', new Date());

    return date;
  };

  return (
    <IMaskInput
      {...other}
      mask={Date}
      pattern="d{/}`m{/}`Y"
      parse={parse}
      format={format}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value: value as string } })
      }
    />
  );
};

export default forwardRef(DateMask) as ElementType;

import { Controller, useFormContext } from 'react-hook-form';
import {
  Flex,
  FormErrorMessage,
  FormLabel,
  RadioProps,
  useRadioGroup,
} from '@chakra-ui/react';
import FormControl from './FormControl';
import { RadioCard } from 'src/components/Form/RadioCard';

type OpenSelectFieldOption = {
  label: string;
  value: string | number;
};

type OpenSelectFieldProps = RadioProps & {
  name: string;
  label?: string;
  options: Array<OpenSelectFieldOption>;
  onChange?: (value: string | number) => void;
};

const OpenSelectField = (props: OpenSelectFieldProps) => {
  const { control } = useFormContext();
  const { name, onChange, options, label } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: onChangeHookForm },
        formState: { isSubmitted },
        fieldState: { isTouched, error },
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getRadioProps } = useRadioGroup({
          name: name,
          onChange: (v) => {
            onChangeHookForm(v);
            if (onChange) onChange(v);
          },
        });

        const group = getRootProps();

        return (
          <FormControl isInvalid={error && (isTouched || isSubmitted)}>
            {label && <FormLabel>{label}</FormLabel>}

            <Flex
              {...group}
              gap={2}
              flexWrap="wrap"
              direction={{ base: 'column', sm: 'row' }}
            >
              {options.map((option) => {
                const radio = getRadioProps({ value: option.value });

                return (
                  <RadioCard
                    key={option.value}
                    {...radio}
                    isChecked={option.value?.toString() == value?.toString()}
                  >
                    {option.label}
                  </RadioCard>
                );
              })}
            </Flex>

            {error && (isTouched || isSubmitted) && (
              <FormErrorMessage>{error.message}</FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default OpenSelectField;

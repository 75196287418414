import { ChangeEvent, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  FormErrorMessage,
} from '@chakra-ui/react';

import FormControl from './FormControl';

type CheckboxProps = ChakraCheckboxProps & {
  label?: string | ReactNode;
  name: string;
};

const Checkbox = (props: CheckboxProps) => {
  const { control } = useFormContext();
  const { name, onChange, label, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: onChangeHookForm, value, onBlur },
        formState: { isSubmitted },
        fieldState: { error, isTouched },
      }) => (
        <FormControl isInvalid={error && (isTouched || isSubmitted)}>
          <ChakraCheckbox
            {...fieldProps}
            name={name}
            onBlur={onBlur}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChangeHookForm(e.target.checked);

              if (onChange) onChange(e);
            }}
            checked={!!value}
          >
            {label}
          </ChakraCheckbox>

          {error && (isTouched || isSubmitted) && (
            <FormErrorMessage>{error.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

export default Checkbox;

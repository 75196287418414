import { Controller, useFormContext } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import FormControl from './FormControl';

type SelectFieldOption = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

type SelectFieldProps = SelectProps & {
  name: string;
  label?: string;
  options: Array<SelectFieldOption>;
};

const SelectField = (props: SelectFieldProps) => {
  const { control } = useFormContext();
  const { name, onChange, options, label, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: onChangeHookForm, onBlur },
        formState: { isSubmitted },
        fieldState: { isTouched, error },
      }) => (
        <FormControl isInvalid={error && (isTouched || isSubmitted)}>
          {label && <FormLabel>{label}</FormLabel>}

          <Select
            {...fieldProps}
            name={name}
            value={value}
            onChange={(e) => {
              onChangeHookForm(e);

              if (onChange) onChange(e);
            }}
            onBlur={onBlur}
          >
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.isDisabled}
                style={{
                  fontWeight: option.isDisabled ? 'bold' : 'normal',
                  color: option.isDisabled ? 'black' : '',
                }}
              >
                {option.label}
              </option>
            ))}
          </Select>

          {error && (isTouched || isSubmitted) && (
            <FormErrorMessage>{error.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectField;

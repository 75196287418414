import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
} from '@chakra-ui/react';

type FormControlPropsType = ChakraFormControlProps & {
  children: React.ReactNode;
};

const FormControl = ({ children, ...props }: FormControlPropsType) => (
  <ChakraFormControl
    mb={4}
    {...props}
  >
    {children}
  </ChakraFormControl>
);

export default FormControl;

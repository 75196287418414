import {
  FormLabel,
  FormLabelProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export interface ILabelWithDescription {
  title: string;
  description: string;
}

type ILabelWithDescriptionProps = ILabelWithDescription & FormLabelProps;

export const LabelWithDescription = ({
  title,
  description,
  ...rest
}: ILabelWithDescriptionProps) => {
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <FormLabel {...rest}>
      <Text
        fontSize="md"
        fontWeight="700"
      >
        {title}
      </Text>
      <Text
        fontSize="sm"
        fontWeight="500"
        color={secondaryTextColor}
      >
        {description}
      </Text>
    </FormLabel>
  );
};
